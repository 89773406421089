import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Login';
// import NavBar from './NavBar';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from "react-router-dom";




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <Routes>
      <Route exact path="/" element={<App/>}/>
        {/* <App /> */}
      {/* </Route> */}
      <Route exact path="/login" element={<Login/>}/>
      {/* <Route path="/login">
        <Login />
      </Route> */}
      {/* <Route path="/dashboard">
        <Dashboard />
      </Route> */}
      </Routes>
    </Router>
);

reportWebVitals();
