import logo from './logo.svg';
import React from 'react';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import NavBar from './NavBar';
// import { useState } from "react"
import axios from 'axios';

import './App.css';
// const [username, setUsername] = useState("")
// const [password, setPassword] = useState("")
// const [message, setMessage] = useState("")

class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            message:'',
            errorcode:'',
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleChange(event,key) {
        //this.setState({key: event.target.value});
        this.setState({
            [key]: event.target.value
          });
      }
    
    async handleSubmit(event) {
        // alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
        let formdata = {
            "username": this.state.username,
            "password": this.state.password
          };
      axios.post("https://oms.lbp.com.hk/checkLogin",formdata)
      .then(res => {
        const message = res.data;
        console.log('Nelson res',res.data)
        this.setState(message);
      })
      .catch(
        (error) => console.log('Network Error',error)
      )
        //   let res = fetch("https://lbp.com.hk/checklogin", {
            //"https://httpbin.org/post"
            // let res = await fetch("/checklogin", {
            //     method: "POST",
            //     body: JSON.stringify({
            //       username: this.state.username,
            //       password: this.state.email,
            //       message: this.state.message,
            //     }),
            //   }).then((resp)=>{
            //     console.log(resp);
            //   });
      }

  render(){
    return (
        <div className="App">
              <NavBar/>
            
              <div id="loginform" className="form-1">
                  <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                              <form >
                                  <div className="form-group">
                                      <input type="text" className="form-control-input" placeholder="Username" required name="username"  onChange={(e)=>this.handleChange(e,'username')}/>
                                  </div>
                                  <div className="form-group">
                                      <input type="password" className="form-control-input" placeholder="password" required  name="password"  onChange={(e)=>this.handleChange(e,'password')}/>
                                  </div>
                                  <div className="form-group">
                                      <button type="submit" className="form-control-submit-button" onClick={(e)=>this.handleSubmit(e)}>Submit</button>
                                  </div>
                                  <div className="error-msg">{this.state.message}</div>
                              </form>
      
                          </div> 
                      </div>
                  </div> 
              </div>

              <div className="copyright bg-gray">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                              <ul className="list-unstyled li-space-lg p-small">
                                  <li><a href="#">Terms & Conditions</a></li>
                                  <li><a href="#">Privacy Policy</a></li>
                              </ul>
                          </div> 
                          <div className="col-lg-6 col-md-12 col-sm-12"> 
                              <p className="p-small statement">Copyright © <a href="#">LBP Limited 科昌科技有限公司</a></p>
                          </div> 
      
                      </div> 
                  </div> 
              </div>
             
              <button id="myBtn">
                  <img src="assets/images/up-arrow.png" alt="alternative" />
              </button>
            
          </div>
      );
  }
}
export default Login;