import React from 'react';
import {Link} from "react-router-dom";
import './App.css';

class NavBar extends React.Component{

    WhatsappLink(text){
      return (`https://wa.me/85263373578?text=${encodeURIComponent(text)}`);
    }
  
    render(){
return (
    <nav id="navbarExample" className="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
    <div className="container">

      
      
         {/* <a className="navbar-brand logo-text" href="https://lbp.com.hk/">LBP 科昌科技有限公司</a>  */}
         <Link to="/" className="navbar-brand logo-text">LBP 科昌科技有限公司</Link>

        <button className="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
            <ul className="navbar-nav ms-auto navbar-nav-scroll">
                {/* <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#header">Home</a>
                </li> */}
                {/* <li className="nav-item">
                    <a className="nav-link" href="#services">Services</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#details">Details</a>
                </li> */}
                <li className="nav-item">
                    <a className="nav-link" href={this.WhatsappLink("我想了解更多關於智慧Pos方案")}>智慧Pos方案</a>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">5大餐廳類型</a>
                    <ul className="dropdown-menu" aria-labelledby="dropdown01">
                        <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於咖啡店/飲品店/快餐店")}>咖啡店/飲品店/快餐店</a></li>
                        <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於茶餐廳/冰室/西餐/酒楼")}>茶餐廳/冰室/西餐/酒楼</a></li>
                        <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於沙律/米線/DIY餐牌")}>沙律/米線/DIY餐牌</a></li>
                        <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於Foodcourt 美食廣場")}>Foodcourt 美食廣場</a></li>
                        <li><a className="dropdown-item" href={this.WhatsappLink("我想了解更多關於中央廚房/虛擬餐廳")}>中央廚房/虛擬餐廳</a></li>
                    </ul>
                </li>
            </ul>
            <span className="nav-item">
                {/* <a className="btn-solid-sm" href="/login">登入 | 餐廳控制台</a> */}
                <Link to="/login" className="btn-solid-sm">登入 | 餐廳控制台</Link>
            </span>
            <span className="nav-item">
                <a className="btn-solid-sm" href="#contact">免費獲取Demo</a>
            </span>
        </div> 
    </div> 
</nav>
);
            }
        }
        export default NavBar;